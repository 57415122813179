import clsx from "clsx";

export interface CardProps {
	children: React.ReactNode | React.ReactNode[];
	className?: string;
	hasLowOpacity?: boolean;
	hasFullHeight?: boolean;
	hasFullWidth?: boolean;
}
export const Card = ({
	children,
	className,
	hasLowOpacity = false,
	hasFullHeight = false,
	hasFullWidth = false,
}: CardProps) => {
	return (
		<div
			className={`mx-auto flex w-full ${clsx(
				!hasFullWidth && "max-w-xl",
			)} ${clsx(hasFullHeight, "h-full")}`}
		>
			<div
				className={`flex rounded backdrop-blur-md ${className} ${
					hasLowOpacity ? "bg-gray9/30" : "bg-gray9/20"
				}`}
			>
				{children}
			</div>
		</div>
	);
};
